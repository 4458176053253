<template>
  <div v-if="uploads.length">
    <div v-for="upload in uploads" :key="upload.filename" class="mb-2">
      <div class="caption grey--text">{{ upload.filename | fileName }}</div>
      <v-progress-linear
        :value="upload.progress"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      uploads: state => state.file_upload.uploads
    })
  }
}
</script>